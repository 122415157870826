import { snakeCase } from 'lodash';

import {
  DataRoomCustomSchemaFieldTypes,
  DataRoomPrivacyTypes,
  DataRoomTypes,
  PricingTriggersKey,
  DataRoomCreateSubmitValues,
} from '@trustwise/design-system';
import { catchHandler, convertBooleanString, convertKeysToSnakeCase } from 'core/utils';
import {
  CreateDataRoomFormHelpers,
  CreateDataSet,
  CustomSchemaEntryAdd,
  DataRoomEdit,
  DataRoomEditFormValues,
  DataRoomPermissionFormHelpers,
  DataRoomPermissionFormValues,
  DataRoomPermissionRevokeValues,
  DeleteDataEntry,
  EditDataSet,
  ManageAccessLink,
  NewDocumentDataEntryFormData,
  UploadDocument,
} from 'dataRooms/types';
import {
  DataEntryItemModel,
  DataRoomDetailModel,
  DataRoomItemModel,
  DataRoomPermissionItemModel,
  DataSetItemModel,
  DataSetModel,
} from 'dataRooms/models';
import { DataRoomAccessLogModel } from 'dataRooms/models/dataRoom';
import { DocumentUpdateFormHelpers, DocumentUpdateFormValues } from 'media/interfaces/formTypes';
import { getApiUrl } from 'utils/urls';
import { getGlobalContext } from 'core/globals';
import { getTriggersStatus } from 'pricing/utils';
import { parseMixedFileInput, onFileDownload, prepareNewDocumentData } from 'media/utils';
import { QueryKeyProp } from 'core/types';
import { SearchQuery } from 'core/forms/interfaces';
import { SetDisabled, SetStateType } from 'types';
import { signFormTransaction, signTransaction } from 'utils/signTransaction';
import { FileModel, SimpleDocumentModel } from 'media/models';
import axios from 'core/axios';
import { IFileModel } from 'media/interfaces';
import { MixedFileInputValue } from 'media/types';
import convertResponseToModel from 'utils/responseToModel';
import { isNativeAppWebview } from 'utils/general';

const { activeEntity: { id: activeEntityId } } = getGlobalContext();

const baseDataRoomsUrlPath = '/data-rooms/';

const getDataRoomDetailUrl = (dataRoomId: string) => `${baseDataRoomsUrlPath}${dataRoomId}/`;
const getBasePermissionsUrl = (dataRoomId: string) => `${getDataRoomDetailUrl(dataRoomId)}permissions/`;
const getBaseDataSetUrl = (dataRoomId: string) => `${getDataRoomDetailUrl(dataRoomId)}data-sets/`;
export const getDataSetDetailUrl = (dataRoomId: string, dataSetId: string) => `${getBaseDataSetUrl(dataRoomId)}${dataSetId}/`;

export const getDataRooms = (): Promise<DataRoomItemModel[]> => (
  axios.get(getApiUrl(baseDataRoomsUrlPath))
    .then(({ data }) => DataRoomItemModel.fromResponse(data))
    .catch(console.error)
);

export const createDataRoom = (values: DataRoomCreateSubmitValues, actions: CreateDataRoomFormHelpers) => {
  const { privacy, type, schema, dataEntryRecordingType, ...restProps } = values;

  if (type === DataRoomTypes.CUSTOM_SCHEMA && !schema?.length) {
    throw new Error('Custom schema is selected but no schema is provided');
  }

  const data = {
    ...convertKeysToSnakeCase(restProps),
    entry_recording_type: dataEntryRecordingType,
    room_type: type,
    is_private: privacy === DataRoomPrivacyTypes.PRIVATE,
    schema: type === DataRoomTypes.CUSTOM_SCHEMA ? schema?.map((item) => ({
      ...convertKeysToSnakeCase(item),
      key: snakeCase(item.label),
    })) : [],
  };

  return signFormTransaction({
    urlPath: baseDataRoomsUrlPath,
    navigatePath: baseDataRoomsUrlPath,
    data,
    actions,
  });
};

export const getDataRoom = ({ queryKey }: QueryKeyProp): Promise<DataRoomDetailModel> => {
  const [, id] = queryKey;

  return (
    axios.get(getApiUrl(getDataRoomDetailUrl(id)))
      .then(({ data }) => DataRoomDetailModel.fromResponse(data))
      .catch(console.error)
  );
};

export const editDataRoom = (
  {
    dataRoomId,
    values,
    actions,
  }: DataRoomEdit,
): Promise<DataRoomEditFormValues> => (
  axios.patch(getApiUrl(getDataRoomDetailUrl(dataRoomId)), convertKeysToSnakeCase(values))
    .then(({ data }) => data)
    .catch((error) => catchHandler(error, actions))
);

export const closeDataRoom = (id: string, setDisabled: SetDisabled) => (
  signTransaction({
    urlPath: `${getDataRoomDetailUrl(id)}close/`,
    setDisabled,
  })
);

export const getDataRoomPermissions = ({ queryKey }: QueryKeyProp): Promise<DataRoomPermissionItemModel[]> => {
  const [, dataRoomId] = queryKey;

  return (
    axios.get(getApiUrl(getBasePermissionsUrl(dataRoomId)))
      .then(({ data: { results } }) => DataRoomPermissionItemModel.fromResponse(results))
      .catch(console.error)
  );
};

export const grantDataRoomPermission = (
  dataRoomId: string,
  values: DataRoomPermissionFormValues,
  actions: DataRoomPermissionFormHelpers,
) => {
  const { participant, permission } = values;

  const data = {
    legal_entity_id: participant.id,
    permission_type: permission,
  };

  return signFormTransaction({
    urlPath: getBasePermissionsUrl(dataRoomId),
    data,
    actions,
  });
};

export const revokeDataRoomPermission = (
  dataRoomId: string,
  values: DataRoomPermissionRevokeValues,
  setDisabled: SetDisabled,
) => {
  const { legalEntityId, permission } = values;

  const data = {
    legal_entity_id: legalEntityId,
    permission_type: permission,
  };

  return signTransaction({
    urlPath: `${getBasePermissionsUrl(dataRoomId)}revoke/`,
    data,
    setDisabled,
  });
};

const generateAccessLink = (dataRoomId: string) => (
  axios.post(getApiUrl(`${getDataRoomDetailUrl(dataRoomId)}access-token/`))
    .then(({ data }) => ({ externalAccessToken: data }))
    .catch(console.error)
) as Promise<{ externalAccessToken: string }>;

const disableAccessLink = (dataRoomId: string) => (
  axios.post(getApiUrl(`${getDataRoomDetailUrl(dataRoomId)}access-token/remove/`))
    .then(() => ({ externalAccessToken: '' }))
    .catch(console.error)
) as Promise<{ externalAccessToken: string }>;

export const manageExternalLink = ({ dataRoomId, disable }: ManageAccessLink) => (
  disable ? disableAccessLink(dataRoomId) : generateAccessLink(dataRoomId)
);

export const createDataSet = ({ dataRoomId, values, actions }: CreateDataSet): Promise<DataSetItemModel> => (
  axios.post(getApiUrl(getBaseDataSetUrl(dataRoomId)), values)
    .then(({ data }) => DataSetItemModel.fromResponse({ ...data, no_of_entries: 0 }))
    .catch((error) => catchHandler(error, actions))
);

export const getDataSets = ({ queryKey }: QueryKeyProp): Promise<DataSetItemModel[]> => {
  const [, dataRoomId] = queryKey;

  return (
    axios.get(getApiUrl(getBaseDataSetUrl(dataRoomId)))
      .then(({ data }) => DataSetItemModel.fromResponse(data))
      .catch(console.error)
  );
};

export const getDataSet = ({ queryKey }: QueryKeyProp): Promise<DataSetModel> => {
  const [, dataRoomId, dataSetId] = queryKey;
  return (
    axios.get(getApiUrl(getDataSetDetailUrl(dataRoomId, dataSetId)))
      .then(({ data }) => DataSetModel.fromResponse(data))
      .catch(console.error)
  );
};

export const getDataEntries = ({ queryKey }: QueryKeyProp): Promise<DataEntryItemModel[]> => {
  const [, dataRoomId, dataSetId] = queryKey;
  return (
    axios.get(getApiUrl(`${getDataSetDetailUrl(dataRoomId, dataSetId)}entries/`))
      .then(({ data: { results } }) => DataEntryItemModel.fromResponse(results))
      .catch(console.error)
  );
};

export const sealDataSet = (dataRoomId: string, dataSetId: string, setDisabled: SetDisabled) => (
  signTransaction({
    urlPath: `${getDataSetDetailUrl(dataRoomId, dataSetId)}seal`,
    setDisabled,
  })
);

export const onRecordReadAccess = ({
  dataRoomId,
  dataSetId,
  setDisabled,
  shouldRedirect = true,
}: {
  dataRoomId: string,
  dataSetId: string,
  setDisabled?: SetDisabled,
  shouldRedirect?: boolean,
}) => {
  const dataSetDetailPath = getDataSetDetailUrl(dataRoomId, dataSetId);
  signTransaction({
    urlPath: `${dataSetDetailPath}request-access/`,
    navigatePath: shouldRedirect ? dataSetDetailPath : undefined,
    setDisabled,
  });
};

const documentsUrl = '/new-documents/';
const legalEntityParams = {
  related_object_model: 'legalentity',
  related_object_id: activeEntityId,
  app_label: 'legal_entities',
};

export const getExistingDocuments = (search?: SearchQuery) => {
  const { searchString } = search || {};
  const queryParams = searchString ? { search: searchString, ...legalEntityParams } : legalEntityParams;

  return axios.get(getApiUrl(documentsUrl, queryParams))
    .then(({ data: { results } }) => SimpleDocumentModel.fromArrayResponse(results))
    .catch(console.error);
};

export const uploadExistingDocument = (dataRoomId: string, dataSetId: string, documentId: number) => (
  axios.post(getApiUrl(`${getDataSetDetailUrl(dataRoomId, dataSetId)}entries/`), [documentId])
    .then(({ data }) => DataEntryItemModel.fromResponse(data))
    .catch(console.error)
);

export const uploadNewDocument = (
  dataRoomId: string,
  dataSetId: string,
  values: DocumentUpdateFormValues,
  actions: DocumentUpdateFormHelpers,
) => {
  const documentData = prepareNewDocumentData(values);
  return axios.post(getApiUrl(documentsUrl, legalEntityParams), documentData)
    .then(({ data: { document_id: docId } }) => uploadExistingDocument(dataRoomId, dataSetId, docId))
    .catch((error) => catchHandler(error, actions));
};

export const uploadDocument = ({
  dataRoomId,
  dataSetId,
  data,
}: UploadDocument): Promise<DataEntryItemModel> => {
  if (typeof data === 'number') {
    return uploadExistingDocument(dataRoomId, dataSetId, data);
  }
  const { values, actions } = data as NewDocumentDataEntryFormData;
  return uploadNewDocument(dataRoomId, dataSetId, values, actions);
};

export const onEntryFileDownload = (
  dataEntryId: number,
  file: IFileModel,
  urlBase: string,
) => {
  const downloadUrl = getApiUrl(`${urlBase}entries/${dataEntryId}/files/${file.id}/download/`);
  window.location.href = isNativeAppWebview ? file.makeNativeUrl(downloadUrl) : downloadUrl;
};

export const onSchemaFilesOpen = (
  dataEntryId: number,
  fieldKey: string,
  urlBase: string,
) => (
  axios.get(getApiUrl(`${urlBase}entries/${dataEntryId}/${fieldKey}/files/`))
    .then(({ data }) => convertResponseToModel(data || [], FileModel))
    .catch(console.error)
);

export const deleteDataEntry = ({
  dataRoomId,
  dataSetId,
  dataEntryId,
}: DeleteDataEntry): Promise<number | void> => (
  axios.delete(getApiUrl(`${getDataSetDetailUrl(dataRoomId, dataSetId)}entries/${dataEntryId}/`))
    .then(() => dataEntryId)
    .catch(console.error)
);

export const getDataRoomQueryOptions = (dataRoomId: string) => ({ queryKey: ['dataRoom', dataRoomId], queryFn: getDataRoom });

export const deleteDataSet = (dataRoomId: string, dataSetId: string) => (
  axios.delete(getApiUrl(getDataSetDetailUrl(dataRoomId, dataSetId))).catch(console.error)
) as Promise<void>;

export const editDataSet = ({ dataRoomId, dataSetId, values, actions }: EditDataSet) => (
  axios.patch(getApiUrl(getDataSetDetailUrl(dataRoomId, dataSetId)), values)
    .then(({ data }) => DataSetModel.fromResponse(data))
    .catch((error) => catchHandler(error, actions))
);

export const onReportDownload = ({
  fileId,
  dataRoomId,
  dataSetId,
  reports,
}: {
  fileId: number,
  dataRoomId: string,
  dataSetId: string,
  reports: SimpleDocumentModel[],
}) => onFileDownload(fileId, reports, getDataSetDetailUrl(dataRoomId, dataSetId));

export const onCustomEntryAdd = ({
  values,
  actions,
  schema,
  dataRoomId,
  dataSetId,
}: CustomSchemaEntryAdd) => {

  const promises = schema.map(({ fieldType, key }) => {
    const value = values[key];
    switch (fieldType) {
      case DataRoomCustomSchemaFieldTypes.DATE_TIME: {
        const date = values[`${key}_date`];
        const time = values[`${key}_time`];
        const jsISOString = new Date(`${date}T${time}`).toISOString();
        return Promise.resolve(`${jsISOString.substring(0, jsISOString.length - 1)}+00:00`);
      }
      case DataRoomCustomSchemaFieldTypes.DECIMAL:
        return Promise.resolve(value.toString());
      case DataRoomCustomSchemaFieldTypes.BOOLEAN:
        return Promise.resolve(convertBooleanString(value as string) as boolean);
      case DataRoomCustomSchemaFieldTypes.FILES: {
        return parseMixedFileInput(value as MixedFileInputValue);
      }
      default:
        if (typeof value === 'object') {
          return Promise.reject(new Error(`Invalid value for field ${key} of type ${fieldType} is provided`));
        }
        return Promise.resolve(value);
    }
  });

  return Promise.all(promises)
    .then((data) => axios.post(getApiUrl(`${getDataSetDetailUrl(dataRoomId, dataSetId)}entries/`), data))
    .then(({ data: responseData }) => DataEntryItemModel.fromResponse(responseData))
    .catch((error) => catchHandler(error, actions));
};

export const updatePricingAllowances = (
  triggerKey: PricingTriggersKey,
  queryParams: Record<string, string>,
  setPricingActionsAllowances: SetStateType<Record<string, string>>,
) => getTriggersStatus({ triggersKeys: [triggerKey], ...queryParams }).then(
  (data) => {
    setPricingActionsAllowances((prevState) => ({
      ...prevState,
      [triggerKey]: data,
    }));
    return data;
  },
);

export const getAccessLog = ({ queryKey }: QueryKeyProp): Promise<DataRoomAccessLogModel[]> => {
  const [, id] = queryKey;

  return (
    axios.get(getApiUrl(`${getDataRoomDetailUrl(id)}access-logs/`))
      .then(({ data }) => DataRoomAccessLogModel.fromResponse(data))
      .catch(console.error)
  );
};

export const updateDeviceRegistry = (dataRoomId: string, registryAddress: string) => (
  signTransaction({
    urlPath: `${getDataRoomDetailUrl(dataRoomId)}update-device-registry`,
    data: { registry_address: registryAddress },
  })
);
