import { completed, actionRequired } from '../const';
import * as topicActions from './topicActionsContext';

const getMultiSigActionContext = (blockchainId, delegatedContractAddress, multisigTxId) => {
  const label = gettext('View blockchain transaction');
  if (delegatedContractAddress) {
    return {
      path: `/delegation-signatories/blockchain-transactions/${delegatedContractAddress}/${blockchainId}/`,
      label,
    };
  }
  return {
    path: `/signatories/multisig-transactions/${multisigTxId}/`,
    label,
  };
};

const getInboxActionContext = (inboxItem) => {
  let contextByRole = {};
  const { topic, relatedObj, context, role, status, itemId } = inboxItem;

  switch (topic) {
    case 'manual_init_verification':
    case 'account_verification':
    case 'personal_account_verification':
      contextByRole = topicActions.getVerificationContext({
        entityId: context.account_id || relatedObj.id,
        hasOwnerAction: status === actionRequired,
        isCompleted: status === completed,
        accountStatus: context.account_status,
      });
      break;
    case 'board_create_contract':
    case 'board_member_add':
    case 'board_member_vote_update':
    case 'board_member_role_update':
    case 'board_member_remove':
      contextByRole = topicActions.getBoardMemberContext(
        context.company_id,
        topic === 'board_member_remove',
      );
      break;
    case 'company_members_create_contract':
    case 'company_member_add':
    case 'company_member_remove':
      contextByRole = topicActions.getMembershipContext(
        context.company_id,
        topic === 'company_member_remove',
      );
      break;
    case 'company_signatory_add':
    case 'company_signatory_signature_update':
    case 'company_signatory_role_update':
    case 'company_signatory_remove':
      contextByRole = topicActions.getCompanySignatoryContext(
        context.company_id,
        topic === 'company_signatory_remove',
      );
      break;
    case 'document_upload':
    case 'document_signing':
      contextByRole = topicActions.getDocumentContext({
        documentId: context.document_id,
      });
      break;
    case 'share_registry_excerpt':
    case 'custodian_share_registry_excerpt':
      contextByRole = topicActions.getDocumentContext({
        documentId: relatedObj.id,
        label: gettext('View excerpt'),
      });
      break;
    case 'token_subscription':
    case 'token_subscription_allotment':
    case 'token_subscription_finalization':
    case 'token_subscription_poa_update':
      contextByRole = topicActions.getSubscriptionsContext({
        topic,
        subscriptionId: relatedObj.id,
        relationshipId: context.relationship_id,
        isListFinalized: context.is_list_finalized,
      });
      break;
    case 'token_conversion':
      contextByRole = topicActions.getConversionContext(relatedObj.id);
      break;
    case 'token_allotment':
      contextByRole = topicActions.getAllotmentContext({
        btrxId: context.btrx_id,
        portfolioId: context.portfolio_id,
        tokenId: relatedObj.id,
      });
      break;
    case 'token_create':
    case 'token_contract_upgrade':
    case 'token_increase_supply':
      contextByRole = topicActions.getTokenContext(
        relatedObj.id,
        context.token_type,
      );
      break;
    case 'simple_token_transfer':
      contextByRole = topicActions.getSimpleTransferContext({
        tokenId: relatedObj.id,
        portfolioId: context.portfolio_id,
        isPaymentToken: context.token_type === 'TPT',
      });
      break;
    case 'token_transfer_board_approval':
    case 'token_transfer':
    case 'property_token_transfer':
      contextByRole = topicActions.getTransferContext({
        btrxId: relatedObj.id,
        senderPortfolioId: context.sender_portfolio_id,
        tokenId: context.token_id,
        companyId: context.company_id,
      });
      break;
    case 'beneficial_owner_report':
      contextByRole = topicActions.getBoReportContext(relatedObj.id);
      break;
    case 'delegate_add':
    case 'delegate_update':
    case 'delegate_remove':
      contextByRole = topicActions.getDelegateContext({
        portfolioId: context.portfolio_id,
        relationshipId: context.relationship_id,
        isDelegated: context.is_delegated,
      });
      break;
    case 'contact_request':
      contextByRole = topicActions.getContactsContext(relatedObj.id);
      break;
    case 'meeting_proposal_voting':
    case 'meeting_open_proposal_voting':
    case 'meeting_close_proposal_voting':
    case 'gov_event_preparation':
    case 'meeting_check_in':
    case 'meeting_ongoing':
    case 'gov_event_meeting':
    case 'gov_event_resolution':
    case 'meeting_release_proxy_votes':
    case 'meeting_cancel_proxy_votes':
    case 'meeting_submit_proxy_instructions':
    case 'meeting_finalize_protocol':
    case 'meeting_minutes_sign':
    case 'resolution_ongoing':
    case 'resolution_results':
    case 'agenda_item_voting_legacy':
    case 'meeting_minutes_sent':
      contextByRole = topicActions.getGovernanceEventContext({
        eventId: relatedObj.id,
        isVoting: topic === 'agenda_item_voting_legacy',
      });
      break;
    case 'meeting_minutes':
    case 'resolution_results_invitees':
    case 'meeting_participation':
      contextByRole = topicActions.getGovernanceEventContext({
        eventId: relatedObj.id,
        visibilityId: status === actionRequired ? itemId : null,
      });
      break;
    case 'information_sent':
      contextByRole = topicActions.getInformationContext(relatedObj.id);
      break;
    case 'potential_shareholder_add':
      contextByRole = topicActions.getPotentialShareholdersContext(context.whitelist_id);
      break;
    case 'certificate_issuer_registry_create':
    case 'certificate_issuer_add_to_registry':
      contextByRole = topicActions.getCertificateIssuerContext(context.registry_id);
      break;
    case 'certificate_contract_create':
    case 'certificate_issuer_registry_add':
      contextByRole = topicActions.getCertificatesContext(context.certificate_id);
      break;
    case 'consortium_member_invitation':
    case 'consortium_member_application':
      contextByRole = topicActions.getConsortiumMembershipContext({
        memberId: relatedObj.id,
        isInvited: topic === 'consortium_member_invitation',
        memberType: 'consortium_member',
      });
      break;
    case 'validator_invitation':
    case 'validator_application':
      contextByRole = topicActions.getConsortiumMembershipContext({
        memberId: relatedObj.id,
        isInvited: topic === 'validator_invitation',
        memberType: 'validator',
      });
      break;
    case 'peer_owner_invitation':
    case 'peer_owner_application':
      contextByRole = topicActions.getConsortiumMembershipContext({
        memberId: relatedObj.id,
        isInvited: topic === 'peer_owner_invitation',
        memberType: 'peer_owner',
      });
      break;
    case 'cbdc_issue_banknotes':
      contextByRole = topicActions.getCBDCContext(relatedObj.id);
      break;
    case 'token_valuation':
      contextByRole = topicActions.getValuationContext({
        portfolioId: context.portfolio_id,
        tokenId: context.token_id,
        timeSeriesId: relatedObj.id,
      });
      break;
    case 'invoice_payment':
      contextByRole = topicActions.getInvoiceContext();
      break;
    case 'contract_upgrade':
      contextByRole = topicActions.getContractUpgradeContext(
        context.type,
        status === completed,
      );
      break;
    case 'accept_terms_and_conditions':
      contextByRole = status !== completed ? {
        recipient: {
          path: `/registered-objects/${context.token_id}/accept-terms-and-conditions/`,
          label: gettext('Accept'),
        },
      } : {};
      break;
    case 'utility_token_mint':
    case 'utility_token_burn':
    case 'utility_token_transfer':
      contextByRole = topicActions.getUtilityTokenContext({
        tokenId: relatedObj.id,
        portfolioId: context.portfolio_id,
      });
      break;
    case 'permissions_registry_member_add':
    case 'permissions_registry_member_update':
    case 'permissions_registry_member_delete':
      contextByRole = topicActions.getPermissionsRegistryContext({
        registryId: relatedObj.id,
        memberId: context.member_id,
      });
      break;
    case 'create_limit_order_sell':
    case 'accept_limit_order_sell':
      contextByRole = topicActions.getMarketsContext({ tokenId: context.token_id });
      break;
    case 'data_room_create':
      contextByRole = topicActions.getDataRoomContext({ dataRoomId: context.data_room_id });
      break;
    case 'data_room_close':
    case 'data_room_permission_add':
    case 'data_room_permission_remove':
    case 'data_room_update_device_registry':
      contextByRole = topicActions.getDataRoomContext({ dataRoomId: relatedObj.id, permissionType: context.permission_type });
      break;
    case 'data_set_request_read_access':
    case 'data_room_close_data_set':
    case 'data_room_review_data_set':
      contextByRole = topicActions.getDataSetContext({
        dataRoomId: context.data_room_id,
        dataSetId: relatedObj.id,
        showParticipantAction: context.is_data_room_open || context.reviewed_by_current_entity,
      });
      break;
    default:
      contextByRole = {};
      break;
  }
  return contextByRole[role];
};

export {
  getMultiSigActionContext,
  getInboxActionContext,
};
