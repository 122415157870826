import axios from 'core/axios';
import { getApiUrl } from 'utils/urls';
import { DeviceModel, DeviceRegistryModel } from './models';

export const getRegistries = (): Promise<DeviceRegistryModel[]> => (
  axios.get(getApiUrl('/device-registries'))
    .then(({ data }) => DeviceRegistryModel.fromResponse(data))
);

export const getDevices = (registryId: number): Promise<DeviceModel[]> => (
  axios.get(getApiUrl(`/device-registries/${registryId}/devices`))
    .then(({ data }) => DeviceModel.fromResponse(data))
);
