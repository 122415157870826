import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  DataRoomCreateForm,
  DataRoomCreateScreen,
  DataRoomCreateScreens,
  DataRoomPrivacyType,
  DataRoomType,
  DataRoomTypes,
  PricingTriggersKeys,
  DataEntryRecordingType,
} from '@trustwise/design-system';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { AllowedPricingActionsProps } from 'pricing/types';
import { TriggerAllowanceStatuses } from 'pricing/consts';
import { createDataRoom } from './actions';

export const DataRoomCreateView = ({ allowedPricingActions }: AllowedPricingActionsProps) => {
  const navigate = useNavigate();
  const [currentScreen, setCurrentScreen] = useState<DataRoomCreateScreen>(
    DataRoomCreateScreens.SETTINGS,
  );

  const defaultRoomType: DataRoomType = useMemo(() => (
    allowedPricingActions?.includes(PricingTriggersKeys.DATA_ROOM_CREATION) ? '' as DataRoomType : DataRoomTypes.DOCUMENTS
  ), [allowedPricingActions]);

  const initialValues = {
    name: '',
    description: '',
    type: defaultRoomType,
    contributorsEnabled: false,
    verifiersEnabled: false,
    externalReadersEnabled: false,
    privacy: '' as DataRoomPrivacyType,
    dataEntryRecordingType: '' as DataEntryRecordingType,
  };

  const backButtonProps = currentScreen === DataRoomCreateScreens.SETTINGS
    ? { href: '/', useRouterLink: true }
    : { onClick: () => setCurrentScreen(DataRoomCreateScreens.SETTINGS) };

  const pricingActionsAllowances = useMemo(() => {
    const allowances = {};
    allowedPricingActions?.forEach((item) => {
      allowances[item] = TriggerAllowanceStatuses.ALLOWED;
    });
    return allowances;
  }, [allowedPricingActions]);

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('New Data Room')}
      headerLeft={<BackButton {...backButtonProps} />}
    >
      <DataRoomCreateForm
        pricingActionsAllowances={pricingActionsAllowances}
        currentScreen={currentScreen}
        setCurrentScreen={setCurrentScreen}
        initialValues={initialValues}
        onSubmit={createDataRoom}
        onCancel={() => { navigate('/'); }}
      />
    </PageContent>
  );
};
