import { Device, DeviceRegistry, DeviceLocation, DeviceType } from '@trustwise/design-system';
import { Model } from 'core/model';
import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';
import { convertKeysToCamelCase } from 'core/utils';
import { DeviceRegistryResponseData, RegisteredDeviceResponseData } from './interfaces';

export class DeviceRegistryModel extends Model implements DeviceRegistry {
  id: number;
  blockchainAddress: string;
  name: string;
  createdAt: Date;

  constructor({ createdAt, address, ...restProps }: DeviceRegistryResponseData) {
    super(restProps);
    this.createdAt = new Date(createdAt);
    this.blockchainAddress = address;
  }
}

export class DeviceModel extends Model implements Device {
  blockchainAddress: string;
  deviceType: DeviceType;
  deviceId?: string;
  description?: string;
  location: DeviceLocation;
  addedAt: Date;
  addedBy: SimpleLegalEntityModel;

  constructor({ addedBy, addedAt, location, ...restProps }: RegisteredDeviceResponseData) {
    super(restProps);
    this.addedAt = new Date(addedAt);
    this.addedBy = new SimpleLegalEntityModel(addedBy);
    this.location = convertKeysToCamelCase(location);
  }
}
