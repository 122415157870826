import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  DataRoomDetailView as DataRoomDetailViewDS,
  PricingTriggersKey,
  PricingTriggersKeys,
  DataRoomActions,
  DataRoomPermissionType,
  DataRoomPermissionTypes,
} from '@trustwise/design-system';
import {
  DataRoomPermissionFormHelpers,
  DataRoomPermissionFormValues,
} from 'dataRooms/types';
import { DataRoomDetailModel, DataSetItemModel } from 'dataRooms/models';
import { PermissionsByPricingTriggers } from 'dataRooms/consts';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import useContacts from 'contacts/hooks';
import { useRefetchOnTxProcessed } from 'core/hooks';
import { AllowedPricingActionsProps } from 'pricing/types';
import { ReactQueryMutationStatuses } from 'core/const';
import { getButtonInteraction, getRouterLinkInteraction } from 'core/utils';
import { getDevices, getRegistries } from 'deviceRegistries/actions';
import {
  closeDataRoom,
  createDataSet,
  editDataRoom,
  manageExternalLink,
  getDataRoomPermissions,
  getDataSets,
  grantDataRoomPermission,
  revokeDataRoomPermission,
  getDataRoomQueryOptions,
  onRecordReadAccess,
  updatePricingAllowances,
  getAccessLog,
  updateDeviceRegistry,
} from './actions';

export const DataRoomDetailView = ({ allowedPricingActions }: AllowedPricingActionsProps) => {
  const queryClient = useQueryClient();
  const { dataRoomId } = useParams();
  const [pricingActionsAllowances, setPricingActionsAllowances] = useState({});
  const [fetchAccessLogData, setFetchAccessLogData] = useState(false);

  const setRefetchQueryKeys = useRefetchOnTxProcessed({ exact: false });
  if (!dataRoomId) {
    throw new Error('dataRoomId is required');
  }

  const setPermissionsRefetchQueryKeys = () => setRefetchQueryKeys(['permissions', dataRoomId]);
  const setDataRoomRefetchQueryKeys = () => setRefetchQueryKeys(['dataRoom', dataRoomId]);

  const { onFetch } = useContacts(false, { 'exclude-custodial': 1 });

  const { data: dataRoom } = useQuery(getDataRoomQueryOptions(dataRoomId));
  const { data: dataSets } = useQuery({ queryKey: ['dataSets', dataRoomId], queryFn: getDataSets, enabled: !!dataRoom });
  const { data: permissions } = useQuery({
    queryKey: ['permissions', dataRoomId],
    queryFn: getDataRoomPermissions,
    enabled: !!dataRoom?.isOwner,
  });
  const { data: accessLogs } = useQuery({
    queryKey: ['accessLog', dataRoomId],
    queryFn: getAccessLog,
    enabled: !!dataRoom && fetchAccessLogData,
  });

  const { data: devices } = useQuery({
    queryKey: ['dataRoom', dataRoomId, 'devices'],
    queryFn: () => getDevices(dataRoom?.deviceRegistry?.id as number),
    enabled: !!dataRoom?.deviceRegistry,
  });

  const { mutateAsync: updateDataRoom } = useMutation({
    mutationFn: editDataRoom,
    onSuccess: (updatedValues) => {
      queryClient.setQueryData(
        ['dataRoom', dataRoomId],
        (oldDataRoom: DataRoomDetailModel) => oldDataRoom.updatePlainValues(updatedValues),
      );
    },
  });

  const { mutateAsync: updateDataSets, status: dataSetCreationStatus } = useMutation({
    mutationFn: createDataSet,
    onSuccess: (newDataSet) => {
      queryClient.setQueryData(['dataSets', dataRoomId], (
        oldData?: DataSetItemModel[],
      ) => (oldData ? [newDataSet, ...oldData] : [newDataSet]));
    },
  });

  const { mutateAsync: manageExternalAccessLink } = useMutation({
    mutationFn: manageExternalLink,
    onSuccess: (updatedValue) => {
      queryClient.setQueryData(
        ['dataRoom', dataRoomId],
        (oldDataRoom: DataRoomDetailModel) => oldDataRoom.updatePlainValues(updatedValue),
      );
    },
  });

  useQuery({
    queryKey: ['dataRoom', dataRoomId, 'triggerStatus', 'dataSetCreation'],
    queryFn: () => updatePricingAllowances(
      PricingTriggersKeys.DATA_SET_CREATION,
      { dataRoomId },
      setPricingActionsAllowances,
    ),
    enabled: [ReactQueryMutationStatuses.SUCCESS, ReactQueryMutationStatuses.IDLE].includes(dataSetCreationStatus),
  });

  useQueries({
    queries: Object.entries(PermissionsByPricingTriggers).map(([triggerKey, permissionType]) => {
      const pricingTrigger = triggerKey as PricingTriggersKey;
      return {
        queryKey: ['permissions', dataRoomId, 'triggerStatus', triggerKey],
        queryFn: () => updatePricingAllowances(pricingTrigger, { dataRoomId, permissionType }, setPricingActionsAllowances),
        enabled: !!allowedPricingActions?.includes(pricingTrigger),
      };
    }),
  });

  const filterPermissionsByType = (permissionType: DataRoomPermissionType) => (
    permissions?.filter((permission) => permission.permissionType === permissionType)
  );

  const handleDataSetClick = (id: number) => {
    const dataSet = dataSets?.find((item) => item.id === id);
    if (!dataRoom) {
      throw new Error('Data room is required');
    }
    const shouldRecordAccess = dataSet?.getShouldConfirmAccess(dataRoom.accessRole);
    if (shouldRecordAccess) {
      return getButtonInteraction(() => onRecordReadAccess({ dataRoomId, dataSetId: `${id}` }));
    }
    return getRouterLinkInteraction(`data-sets/${id}/`);
  };

  const detailActions: DataRoomActions = {
    onDataRoomEdit: (values, actions) => updateDataRoom({ dataRoomId, values, actions }),
    onDataSetAdd: (values, actions) => updateDataSets({ dataRoomId, values, actions }),
    onDataSetClick: (id) => handleDataSetClick(id),
    onContactsFetch: onFetch,
    onContactSearch: ({ searchString }) => onFetch(searchString),
    onDataRoomClose: (_ev, setDisabled) => (
      closeDataRoom(dataRoomId, setDisabled)
        .then(setDataRoomRefetchQueryKeys)
    ),
    onParticipantAdd: (values, actions) => (
      grantDataRoomPermission(dataRoomId, values as DataRoomPermissionFormValues, actions as DataRoomPermissionFormHelpers)
        .then(setPermissionsRefetchQueryKeys)
    ),
    onPermissionRevoke: (permission, legalEntityId, setDisabled) => (
      revokeDataRoomPermission(dataRoomId, { permission, legalEntityId }, setDisabled).then(setPermissionsRefetchQueryKeys)
    ),
    onLinkReset: () => manageExternalAccessLink({ dataRoomId, disable: false }),
    onLinkDisable: () => manageExternalAccessLink({ dataRoomId, disable: true }),
    onLinkGenerate: () => manageExternalAccessLink({ dataRoomId, disable: false }),
    onAccessLogClick: () => setFetchAccessLogData(true),
    onSelectRegistry: ({ blockchainAddress }) => (
      updateDeviceRegistry(dataRoomId, blockchainAddress)
        .then(setDataRoomRefetchQueryKeys)
    ),
    onFetchRegistries: getRegistries,
  };

  return (
    <PageContent
      fullscreen
      pageHeading={dataRoom?.name || ''}
      headerLeft={<BackButton href="/" useRouterLink />}
    >
      <DataRoomDetailViewDS
        dataRoom={dataRoom?.toObject()}
        dataSets={dataSets}
        verifiers={filterPermissionsByType(DataRoomPermissionTypes.VERIFY)}
        contributors={filterPermissionsByType(DataRoomPermissionTypes.WRITE)}
        readers={filterPermissionsByType(DataRoomPermissionTypes.READ)}
        accessLogs={accessLogs}
        actions={detailActions}
        pricingActionsAllowances={pricingActionsAllowances}
        devices={dataRoom?.deviceRegistry ? devices : []}
      />
    </PageContent>
  );
};
